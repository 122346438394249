import Vue from "vue";
import Vuex from "vuex";

import distributors from "./modules/distributors";
import flags from "./modules/flags";
import messageTypes from "./modules/message-types";
import products from "./modules/products";
import sentiments from "./modules/sentiments";
import states from "./modules/states";
import subjects from "./modules/subjects";
import tickets from "./modules/tickets";
import warrantyCoverages from "./modules/warranty-coverages";
import warrantyDocumentStatuses from "./modules/warranty-document-statuses";
import warrantyStores from "./modules/warranty-stores";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    distributors,
    flags,
    messageTypes,
    products,
    sentiments,
    states,
    subjects,
    tickets,
    warrantyCoverages,
    warrantyDocumentStatuses,
    warrantyStores
  }
});
