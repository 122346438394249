import axios from "axios";

const headers = { Authorization: "85c5c62e84e10517c3b1824adee58a1f" };
const params = { filter: "moura0800.garantiaCobertura" };
const url = "https://moura-emissary-prod.herokuapp.com/v1/zendesk/tags";

const state = {
  warrantyCoverages: []
};

const getters = {
  allWarrantyCoverages: state => state.warrantyCoverages
};

const actions = {
  async fetchWarrantyCoverages({ commit }) {
    const response = await axios.get(url, {
      params,
      headers
    });
    commit("setWarrantyCoverages", response.data);
  }
};

const mutations = {
  setWarrantyCoverages: (state, warrantyCoverages) =>
    (state.warrantyCoverages = warrantyCoverages)
};

export default {
  state,
  getters,
  actions,
  mutations
};
