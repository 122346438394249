<template>
  <div id="app">
    <div class="container">
      <form @submit="onSubmit">
        <div class="form-group">
          <label for="user-name">Nome:</label>
          <input
            class="form-control form-control-sm"
            id="user-name"
            name="userName"
            readonly
            type="text"
            v-model="newTicket.user.name"
          />
        </div>

        <div class="form-row">
          <div class="col">
            <div class="form-group">
              <label for="user-phone">Telefone:</label>
              <input
                class="form-control form-control-sm"
                id="user-phone"
                name="userPhone"
                readonly
                type="tel"
                v-model="newTicket.user.phone"
              />
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label for="user-city">Cidade:</label>
              <vue-google-autocomplete
                classname="form-control form-control-sm"
                id="user-city"
                name="userCity"
                placeholder=""
                type="text"
                v-model="newTicket.customFields[0].value"
                v-on:error="getCityDataError"
                v-on:placechanged="getCityData"
                country="br"
                types="(cities)"
              >
              </vue-google-autocomplete>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label for="state">Estado:</label>
              <select
                class="custom-select custom-select-sm"
                id="state"
                name="state"
                v-model="newTicket.customFields[1].value"
              >
                <option disabled value>…</option>
                <option
                  v-bind:key="state.slug"
                  v-bind:value="state.slug"
                  v-for="state in allStates"
                >
                  {{ state.title }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <div class="form-row">
          <div class="col">
            <div class="form-group">
              <label for="subject">Assunto:</label>
              <select
                class="custom-select custom-select-sm"
                id="subject"
                name="subject"
                v-model="newTicket.subject"
              >
                <option disabled value>…</option>
                <option
                  v-bind:key="subject.slug"
                  v-bind:value="subject.slug"
                  v-for="subject in allSubjects"
                >
                  {{ subject.title }}
                </option>
              </select>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label for="message-type">Teor:</label>
              <select
                class="custom-select custom-select-sm"
                id="message-type"
                name="messageType"
                v-model="newTicket.messageType"
              >
                <option disabled value>…</option>
                <option
                  v-bind:key="messageType.slug"
                  v-bind:value="messageType.slug"
                  v-for="messageType in allMessageTypes"
                >
                  {{ messageType.title }}
                </option>
              </select>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label for="sentiment">Sentimento:</label>
              <select
                class="custom-select custom-select-sm"
                id="sentiment"
                name="sentiment"
                v-model="newTicket.sentiment"
              >
                <option disabled value>…</option>
                <option
                  v-bind:key="sentiment.slug"
                  v-bind:value="sentiment.slug"
                  v-for="sentiment in allSentiments"
                >
                  {{ sentiment.title }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <div class="form-row">
          <div class="col">
            <div class="form-group">
              <label for="product">Produto:</label>
              <select
                class="custom-select custom-select-sm"
                id="product"
                name="product"
                v-model="newTicket.product"
              >
                <option disabled value>…</option>
                <option
                  v-bind:key="product.slug"
                  v-bind:value="product.slug"
                  v-for="product in allProducts"
                >
                  {{ product.title }}
                </option>
              </select>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label for="warranty-bought-at">Onde comprou:</label>
              <select
                class="custom-select custom-select-sm"
                id="warranty-bought-at"
                name="warrantyBoughtAt"
                v-model="newTicket.warrantyBoughtAt"
              >
                <option disabled value>…</option>
                <option
                  v-bind:key="warrantyStore.slug"
                  v-bind:value="warrantyStore.slug"
                  v-for="warrantyStore in allWarrantyStores"
                >
                  {{ warrantyStore.title }}
                </option>
              </select>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label for="warranty-coverage">Cobertura:</label>
              <select
                class="custom-select custom-select-sm"
                id="warranty-coverage"
                name="warrantyCoverage"
                v-model="newTicket.warrantyCoverage"
              >
                <option disabled value>…</option>
                <option
                  v-bind:key="warrantyCoverage.slug"
                  v-bind:value="warrantyCoverage.slug"
                  v-for="warrantyCoverage in allWarrantyCoverages"
                >
                  {{ warrantyCoverage.title }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <div class="form-row">
          <div class="col">
            <div class="form-group">
              <label for="warranty-document-status">Situação documental:</label>
              <select
                class="custom-select custom-select-sm"
                id="warranty-document-status"
                name="warrantyDocumentStatus"
                v-model="newTicket.warrantyDocumentStatus"
              >
                <option disabled value>…</option>
                <option
                  v-bind:key="warrantyDocumentStatus.slug"
                  v-bind:value="warrantyDocumentStatus.slug"
                  v-for="warrantyDocumentStatus in allWarrantyDocumentStatuses"
                >
                  {{ warrantyDocumentStatus.title }}
                </option>
              </select>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label for="distributor">Distribuidor:</label>
              <select
                class="custom-select custom-select-sm"
                id="distributor"
                name="distributor"
                v-model="newTicket.distributor"
              >
                <option disabled value>…</option>
                <option
                  v-bind:key="distributor.slug"
                  v-bind:value="distributor.slug"
                  v-for="distributor in allDistributors"
                >
                  {{ distributor.title }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <div class="form-group">
          <label>Flags:</label>
          <div class="flags">
            <div
              class="custom-control custom-checkbox"
              v-bind:key="flag.slug"
              v-for="flag in allFlags"
            >
              <input
                class="custom-control-input"
                name="flag.id"
                type="checkbox"
                v-bind:id="flag.slug"
                v-bind:value="flag.slug"
                v-model="newTicket.flags"
              />
              <label class="custom-control-label" v-bind:for="flag.slug">
                {{ flag.title }}
              </label>
            </div>
          </div>
        </div>

        <div class="form-row">
          <div class="col">
            <button
              class="btn btn-sm btn-block btn-secondary"
              type="button"
              v-bind:disabled="loading"
              @click="resetTicket"
            >
              Limpar
            </button>
          </div>
          <div class="col-8">
            <button
              class="btn btn-sm btn-block btn-primary"
              type="submit"
              v-bind:disabled="loading"
            >
              <span v-if="!loading">Enviar</span>
              <span v-if="loading">Enviando…</span>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import VueGoogleAutocomplete from "vue-google-autocomplete";

export default {
  name: "app",
  components: { VueGoogleAutocomplete },
  data: function() {
    return {
      appTitle: "LivePerson Sidekik Moura"
    };
  },
  methods: {
    ...mapActions([
      "fetchDistributors",
      "fetchFlags",
      "fetchMessageTypes",
      "fetchProducts",
      "fetchSentiments",
      "fetchStates",
      "fetchSubjects",
      "fetchWarrantyCoverages",
      "fetchWarrantyDocumentStatuses",
      "fetchWarrantyStores",
      "formatTicket",
      "resetTicket",
      "sendTicket",
      "setTicketState",
      "setTicketTranscript",
      "setTicketUserCity",
      "setTicketUserName",
      "setTicketUserPhone"
    ]),
    getCityData: function(cityData) {
      this.setTicketState(cityData.administrative_area_level_1);
      this.setTicketUserCity(cityData.locality);
    },
    getCityDataError: function() {
      alert(
        "Não foi possível se conectar ao Google Maps para achar a cidade e a UF automaticamente. Por favor, preencha manualmente."
      );
    },
    initLivePersonTagAgentSKD() {
      // eslint-disable-next-line no-undef
      var SDK = lpTag.agentSDK;

      SDK.init();

      SDK.get(
        "authenticatedData",
        response => {
          this.setTicketUserPhone(response);
        },
        error => {
          // eslint-disable-next-line no-console
          console.error("get(authenticatedData)", error);
        }
      );

      SDK.get(
        "chatTranscript.lines",
        response => {
          this.setTicketTranscript(response);
        },
        error => {
          // eslint-disable-next-line no-console
          console.error("get(chatTranscript).lines", error);
        }
      );

      SDK.get(
        "visitorInfo",
        response => {
          this.setTicketUserName(response);
        },
        error => {
          // eslint-disable-next-line no-console
          console.error("get(visitorInfo)", error);
        }
      );
    },
    onSubmit(e) {
      e.preventDefault();
      this.formatTicket();
      this.sendTicket(this.newTicket);
    }
  },
  computed: mapGetters([
    "allDistributors",
    "allFlags",
    "allMessageTypes",
    "allProducts",
    "allSentiments",
    "allStates",
    "allSubjects",
    "allWarrantyCoverages",
    "allWarrantyDocumentStatuses",
    "allWarrantyStores",
    "loading",
    "newTicket"
  ]),
  created() {
    this.fetchDistributors();
    this.fetchFlags();
    this.fetchMessageTypes();
    this.fetchProducts();
    this.fetchSentiments();
    this.fetchStates();
    this.fetchSubjects();
    this.fetchWarrantyCoverages();
    this.fetchWarrantyDocumentStatuses();
    this.fetchWarrantyStores();
    this.initLivePersonTagAgentSKD();
  }
};
</script>

<style>
#app {
  padding-top: 16px;
  padding-bottom: 16px;
}

.form-group {
  margin-bottom: 0.5rem;
}

.flags {
  display: flex;
  flex-wrap: wrap;
}

.flags .custom-control {
  flex-basis: 33.33333333%;
}

label {
  margin-bottom: 0.2rem;
  font-size: 0.875rem;
}

@media (prefers-color-scheme: dark) {
  body {
    color: white;
    background-color: #03002a;
  }

  .form-control {
    color: white;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: rgba(255, 255, 255, 0.3);
    transition: border-color 0.15s ease-in-out,
      background-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
  }

  .form-control:focus {
    color: white;
    background-color: transparent;
    border-color: rgba(255, 255, 255, 0.6);
    -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.25);
  }

  .form-control[readonly] {
    color: rgba(255, 255, 255, 0.6);
    background-color: transparent;
    border-color: rgba(255, 255, 255, 0.3);
  }

  .custom-control-label:before {
    background-color: rgba(255, 255, 255, 0.1);
    border-color: rgba(255, 255, 255, 0.3);
  }

  .custom-select {
    color: white;
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23ffffff' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
      no-repeat right 0.75rem center/8px 10px;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: rgba(255, 255, 255, 0.3);
    -webkit-appearance: none;
  }

  .custom-select:focus {
    background-color: transparent;
    border-color: rgba(255, 255, 255, 0.6);
    -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.25);
  }

  .custom-select option {
    color: #333;
  }
}
</style>
