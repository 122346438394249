import axios from "axios";

const getDefaultState = () => {
  return {
    newTicket: {
      conversationId: "",
      customFields: [
        { id: "44988008", value: "" },
        { id: "80575087", value: "" }
      ],
      distributor: "",
      flags: [],
      messageType: "",
      product: "",
      sentiment: "",
      subject: "",
      tags: [],
      transcript: [],
      user: {
        name: "",
        phone: ""
      },
      warrantyBoughtAt: "",
      warrantyCoverage: "",
      warrantyDocumentStatus: ""
    },
    loading: false
  };
};

const state = getDefaultState();

const getters = {
  loading: state => state.loading,
  newTicket: state => state.newTicket
};

const actions = {
  formatTicket({ commit }) {
    commit("formatTicket");
  },
  resetTicket({ commit }) {
    commit("resetTicket");
  },
  async sendTicket({ commit }, ticket) {
    commit("setLoadingStatus", true);
    axios
      .post(
        "https://moura-conversation-prod.herokuapp.com/v1/liveperson/conversation-closed/d2fcdbdc-94e2-4563-96aa-4c2ed62083b3",
        ticket
      )
      .then(() => {
        alert("Pronto. Dados enviados!");
        commit("resetTicket");
        commit("setLoadingStatus", false);
      })
      .catch(error => {
        commit("setLoadingStatus", false);
        // eslint-disable-next-line no-console
        console.error(error);
      });
  },
  setTicketState({ commit }, data) {
    commit("setTicketState", data);
  },
  setTicketTranscript({ commit }, data) {
    commit("setTicketTranscript", data);
  },
  setTicketUserCity({ commit }, data) {
    commit("setTicketUserCity", data);
  },
  setTicketUserName({ commit }, data) {
    commit("setTicketUserName", data);
  },
  setTicketUserPhone({ commit }, data) {
    commit("setTicketUserPhone", data);
  }
};

const mutations = {
  formatTicket: state => {
    state.newTicket.tags = [];

    if (state.newTicket.distributor) {
      state.newTicket.tags.push(state.newTicket.distributor);
    }

    if (state.newTicket.messageType) {
      state.newTicket.tags.push(state.newTicket.messageType);
    }

    if (state.newTicket.product) {
      state.newTicket.tags.push(state.newTicket.product);
    }

    if (state.newTicket.sentiment) {
      state.newTicket.tags.push(state.newTicket.sentiment);
    }

    if (state.newTicket.customFields[1].value) {
      state.newTicket.tags.push(state.newTicket.customFields[1].value);
    }

    if (state.newTicket.subject) {
      state.newTicket.tags.push(state.newTicket.subject);
    }

    if (state.newTicket.warrantyBoughtAt) {
      state.newTicket.tags.push(state.newTicket.warrantyBoughtAt);
    }

    if (state.newTicket.warrantyCoverage) {
      state.newTicket.tags.push(state.newTicket.warrantyCoverage);
    }

    if (state.newTicket.warrantyDocumentStatus) {
      state.newTicket.tags.push(state.newTicket.warrantyDocumentStatus);
    }

    if (state.newTicket.flags) {
      state.newTicket.flags.forEach(flag => {
        state.newTicket.tags.push(flag);
      });
    }
  },
  resetTicket: state => {
    state.newTicket.customFields[0].value = "";
    state.newTicket.customFields[1].value = "";
    state.newTicket.distributor = "";
    state.newTicket.flags = [];
    state.newTicket.messageType = "";
    state.newTicket.product = "";
    state.newTicket.sentiment = "";
    state.newTicket.subject = "";
    state.newTicket.tags = [];
    state.newTicket.warrantyBoughtAt = "";
    state.newTicket.warrantyCoverage = "";
    state.newTicket.warrantyDocumentStatus = "";
  },
  setLoadingStatus: (state, status) => {
    state.loading = status;
  },
  setTicketState: (state, data) => {
    state.newTicket.customFields[1].value = data.toLowerCase();
  },
  setTicketTranscript: (state, data) => {
    state.newTicket.conversationId = data[0].dialogId;
    state.newTicket.transcript = data;
  },
  setTicketUserCity: (state, data) => {
    state.newTicket.customFields[0].value = data;
  },
  setTicketUserName: (state, data) => {
    state.newTicket.user.name = data.visitorName;
  },
  setTicketUserPhone: (state, data) => {
    state.newTicket.user.phone = data.customerDetails.imei;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
