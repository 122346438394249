import axios from "axios";

const headers = { Authorization: "85c5c62e84e10517c3b1824adee58a1f" };
const params = { filter: "moura0800.assunto" };
const url = "https://moura-emissary-prod.herokuapp.com/v1/zendesk/tags";

const state = {
  subjects: []
};

const getters = {
  allSubjects: state => state.subjects
};

const actions = {
  async fetchSubjects({ commit }) {
    const response = await axios.get(url, {
      params,
      headers
    });
    commit("setSubjects", response.data);
  }
};

const mutations = {
  setSubjects: (state, subjects) => (state.subjects = subjects)
};

export default {
  state,
  getters,
  actions,
  mutations
};
