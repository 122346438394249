import axios from "axios";

const headers = { Authorization: "85c5c62e84e10517c3b1824adee58a1f" };
const params = { filter: "moura0800.distribuidor" };
const url = "https://moura-emissary-prod.herokuapp.com/v1/zendesk/tags";

const state = {
  distributors: []
};

const getters = {
  allDistributors: state => state.distributors
};

const actions = {
  async fetchDistributors({ commit }) {
    const response = await axios.get(url, {
      params,
      headers
    });
    commit("setDistributors", response.data);
  }
};

const mutations = {
  setDistributors: (state, distributors) => (state.distributors = distributors)
};

export default {
  state,
  getters,
  actions,
  mutations
};
