import axios from "axios";

const headers = { Authorization: "85c5c62e84e10517c3b1824adee58a1f" };
const params = { filter: "moura0800.sentimento" };
const url = "https://moura-emissary-prod.herokuapp.com/v1/zendesk/tags";

const state = {
  sentiments: []
};

const getters = {
  allSentiments: state => state.sentiments
};

const actions = {
  async fetchSentiments({ commit }) {
    const response = await axios.get(url, {
      params,
      headers
    });
    commit("setSentiments", response.data);
  }
};

const mutations = {
  setSentiments: (state, sentiments) => (state.sentiments = sentiments)
};

export default {
  state,
  getters,
  actions,
  mutations
};
